.header {
  .links-dropdown-menu-element {
    position: relative;
    z-index: 20;

    .links-dropdown-menu-wrapper {
      line-height: 1.2;
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: 5px;

      &:not(.visible) {
        display: none;
      }

      .rel {
        .up-arrow {
          z-index: 1;
          position: absolute;
          top: -4px;
          left: 5px;
          text-shadow: 0 -2px 2px rgba(0, 0, 0, 0.3);
          transform: scaleX(1.5);
          color: white;
          font-size: 10px;

          &::after {
            content: "▲";
          }
        }

        .links-dropdown-menu {
          position: relative;
          top: 5px;
          z-index: 0;
          $borderRadius: 2px;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

          border-radius: $borderRadius;
          background-color: white;
          color: black;

          .links-link {
            display: block;
            white-space: nowrap;
            min-width: 10rem;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            color: black;

            &:first-child {
              border-top-left-radius: $borderRadius;
              border-top-right-radius: $borderRadius;
            }
            &:last-child {
              border-bottom-left-radius: $borderRadius;
              border-bottom-right-radius: $borderRadius;
            }

            &:not(:last-child) {
              border-bottom: #ccc;
            }

            &.active {
              background-color: #ccc;
            }

            &:hover:not(.active) {
              background-color: #eee;
            }
          }
        }
      }

      &:not(.show-arrow) {
        .up-arrow {
          display: none;
        }
      }

      &.align-right {
        left: auto;
        right: 0;

        .rel {
          .up-arrow {
            left: auto;
            right: 10px;
          }
        }
      }
    }
  }
}
