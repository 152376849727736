// body.configurator {
    .cfg-configurator-details-popup {
      width: 53rem;

      $slideshowHeight: 60%;

      .resource-summary {
        height: calc(100% - #{$slideshowHeight});
        overflow: auto;

        .resource-summary-content {
          $dimensionsWidth: 10rem;

          padding: 2rem;
          display: flex;
          flex-direction: row;
          min-height: calc(100% - 4rem - 177px);

          .resource-summary-text {
            width: calc(100% - #{$dimensionsWidth});
          }
          .resource-dimensions {
            width: $dimensionsWidth;
          }
          .resource-summary-text {
            .resource-title {
              font-size: 1.4rem;
              font-weight: normal;
              text-transform: uppercase;
              letter-spacing: 1px;
            }
            .resource-price {
              margin: 1rem 0;
              opacity: 0.5;
            }
          }
        }

        .material-swatches-container {
          flex-direction: row;
          vertical-align: top;
          vertical-align: top;
          padding-bottom: 0.5rem;

          &:not(:last-child) {
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
          }

          .material-swatch-container {
            padding: 1rem;
            vertical-align: top;
            display: inline-block;

            &:first-child {
              margin-left: 2rem;
              padding-left: 0rem;
            }

            .material-swatch {
              width: 5rem;
              height: 5rem;
              margin: auto;
              margin-bottom: 1rem;
              border-radius: 2.5rem;
              background-clip: padding-box;
              background-size: cover;
              background-position: 50% center;
              background-repeat: no-repeat;
              background-color: rgba(255, 255, 255, 0.02)
            }

            .material-swatch-label {
              text-align: center;
              width: 7.5rem;
            }
          }
        }
      }

      .resource-slideshow {
        height: $slideshowHeight;
      }
    }
  // }
