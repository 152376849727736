.ui-dimension-constraints {
    .dimension {
        display: block;
        margin-bottom: 0.5rem;
        white-space: nowrap;

        .dimension-title, .dimension-values, .dimension-value, .step-line {
        display: inline-block;
        vertical-align: middle;
        }

        .dimension-title {
        font-weight: bold;
        width: 1.5rem;
        font-size: 1.3rem;
        margin-right: 0.5rem;
        }
        .dimension-values {
        white-space: normal;
        max-width: calc(100% - 2rem);

        .dimension-value {
            font-size: 1rem;

            &.min, &.max, &.fixed {
            padding: 0.1rem 0.6rem;
            }
            &.step{
                width: 1.2rem;
                padding: 0.1 0.2rem;
            }
            &.fixed {
            margin-left: -1px;
            margin-top: -1px;
            min-width: 2.6rem;
            text-align: center;
            }
        }
        }
        .step-line {
        height: 0;
        text-align: center;
        width: 0.5rem;
        }
    }

    &.theme-light {
        $border: 1px solid black;

        color: black;

        .dimension-values, .dimension-value {
        &.min, &.max, &.fixed {
            border: $border;
        }
        }
        .step-line {
        border-bottom: $border;
        }
    }

    &.theme-dark {
        $border: 1px solid #666666;

        color: white;

        .dimension-title {
        opacity: 0.8;
        }
        .dimension-values, .dimension-value {
        &.min, &.max, &.fixed {
            border: $border;
        }
        }
        .step-line {
        border-bottom: $border;
        }
    }
}
