.documentation-documents-show-view {
    width: 53rem;

    .subject-header {
      background-color: #333;
      color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1rem 2rem;

      .subject-title {
        font-size: 1.5rem;
      }
    }

    &.company-violapark {
      .subject-header {
        background-color: #a8c387;
      }
    }

    .subject-body {
      padding: 2rem;

      .subject-icon-container, .summary-detail-dimensions {
        display: inline-block;
        vertical-align: top;
      }
      .subject-icon-container, .summary-detail-dimensions, .summary-detail-notes {
        padding: 1rem;
      }
      .summary-detail-price-range {
        padding: 1rem;
        padding-bottom: 0rem;
      }
      .summary-detail-notes {
        ul {
          padding-left: 1.3rem;
          width: calc(100% - 1.3rem - 1rem);
        }
      }
      .subject-documents-tags-filter-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .document-sets {
        .document-set-title {
          font-weight: bold;
          text-align: center;
        }

        .document-set {
          margin: 1rem 0;

          .document-link {
            display: block;
            margin-bottom: 0.25rem;
            padding: 0.5rem 1rem;
            border-radius: 2px;
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, 0.05);
            max-width: 60rem;

            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }

            .document-title, .document-tag-dots, .document-file-extension {
              display: inline-block;
            }

            .document-title {
              margin-right: .5rem;
              width: 70%;
            }

            .document-tag-dots {
              margin-right: .5rem;
              width: 20%;
            }

            .document-file-extension {
              float: right;
              text-align: right;
              text-transform: uppercase;
              color: rgba(95, 95, 95, 1);
              width: 5%;
            }
          }
        }
      }

      .product-media {
        .media-grid-wrapper {
          margin-top: 2rem;
        }
      }
    }
  }
