@import '../drawings-page.scss';

.drawings-footer,
.drawings-page-footer {
  height: calc(#{$footerHeight} - 1px);
  border-top: $border;
  padding: 0 2rem;
  display: block;
  box-sizing: border-box;
  overflow: hidden;

  .left {
    padding: 1rem 0;
    float: left;
    display: flex;
    align-items: center;
    width: 40%;

    .company-name,
    .project-name,
    .project-link,
    .project-link a {
      display: inline-block;
      vertical-align: middle;
    }

    .company-name {
      font-size: calc(#{$footerHeight} - 3rem);
      margin-top: 0.4rem;
      line-height: 1;
      padding-left: 2rem;

      span {
        opacity: 0.7;
      }
    }

    .project-name {
      opacity: $lightTextOpacity;
      padding-left: 2rem;
      position: relative;
      top: 3px;
      flex: 1;
    }

    .project-link a {
      font-size: 1rem;
      position: relative;
      top: 0.2rem;
      margin-right: 0.8rem;
    }
  }

  .right {
    height: 100%;
    overflow: hidden;
    overflow-wrap: normal;
    float: right;
    display: flex;
    justify-content: flex-end;

    .toggle-menu-visible {
      cursor: pointer;
    }

    .table {
      height: 100%;

      .drawings-footer-right-item {
        display: table-cell;
        padding-right: 1rem;
        padding-left: 1rem;
        vertical-align: middle;
        font-size: $smallFontSize;
        text-align: center;

        [class*='-toggle-option'] {
          text-align: left;
          cursor: pointer;

          &.active {
            font-weight: bold;
          }
        }

        &:not(:last-child) {
          border-right: $borderLight;
        }

        &:last-child {
          padding-right: 0;
        }

        &.drawings-disclaimer {
          text-align: left;
          opacity: 0.9rem;
          width: 24rem;
          line-height: 1.3;
        }

        &.page-number {
          min-width: 3rem;
        }

        &.page-title {
          text-transform: lowercase;
          width: calc(#{$sidePanelWidth} - 3rem - 2px);
          opacity: $lightTextOpacity;

          .page-title-title {
            text-align: center;
            text-transform: lowercase;
            border: none;
          }
        }
      }
    }
  }
}

.drawings-footer,
.drawings-page-footer {
  .page-title {
    min-width: calc(#{$sidePanelWidth} - 3rem) !important;
  }
}