.accessories-view {
  width: 100%;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 100px;

  overflow: scroll;

  h2 {
    margin: 0;
  }

  .accessories-list {
    width: 100%;
    .accessories-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product-icon {
        width: 210px;
        height: 120px;
        img {
          width: 100%;
        }
      }

      .info-icon-container {
        .info-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
