.custom-tooltip-parent {
  position: relative;
}

.custom-tooltip-parent-expanded-property-field-option {
  position: relative;
}

.custom-tooltip-parent-expanded-property-field-option-tooltip-bottom {
  position: relative;
}

.custom-tooltip-container {
  opacity: 0;
  position: absolute;
}

.custom-tooltip-container-expanded-property-field-option {
  opacity: 0;
  position: absolute;
}

.custom-tooltip-container-expanded-property-field-option-tooltip-bottom {
  opacity: 0;
  position: absolute;
  z-index: 2000;
}

.custom-tooltip-parent:hover .custom-tooltip-container {
  opacity: 1;
  bottom: 36px;
  padding: 0.5em 1em 0.5em 1em;
  background-color: black;
  color: white;
  font-size: 0.5rem;
  border-radius: 1.5px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.custom-tooltip-parent-expanded-property-field-option:hover .custom-tooltip-container-expanded-property-field-option {
  opacity: 1;
  bottom: 36px;
  padding: 0.5em 1em 0.5em 1em;
  background-color: black;
  color: white;
  font-size: 0.5rem;
  border-radius: 1.5px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.custom-tooltip-parent-expanded-property-field-option-tooltip-bottom:hover .custom-tooltip-container-expanded-property-field-option-tooltip-bottom {
  opacity: 1;
  top: 36px;
  padding: 0.5em 1em 0.5em 1em;
  background-color: black;
  color: white;
  font-size: 0.5rem;
  border-radius: 1.5px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}


.custom-tooltip-parent:hover .custom-tooltip-container:after {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}

.custom-tooltip-parent-expanded-property-field-option:hover .custom-tooltip-container-expanded-property-field-option:after {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}


.custom-tooltip-parent-expanded-property-field-option-tooltip-bottom:hover .custom-tooltip-container-expanded-property-field-option-tooltip-bottom:after {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  border-left: 5px solid transparent;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
}

.canvas-product-details-row-product-title-section {
  .canvas-product-details-row-add-option-dropdown, .canvas-product-details-row-delete-product-icon {
    opacity: 0;
  }
}

.canvas-product-details-row-product-title-section:hover {
   .canvas-product-details-row-add-option-dropdown, .canvas-product-details-row-delete-product-icon {
    opacity: 1;
   }
}

.product-details-row-product-option {
  .product-details-row-option-x-icon {
    opacity: 0;
  }
}

.product-details-row-product-option:hover {
  .product-details-row-option-x-icon {
    opacity: 0.5;
  }
}

.hoverable-element {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
