body.cfg-drawings-index-view {
  .header {
    display: none;
  }

  position: relative;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);

  @media print {
    .content {
      margin-top: 0;
    }

    .drawings-controls {
      display: none;
    }

    .hide-print {
      display: none;
    }
  }

  @media not print {
    .content {
      padding-top: 0;
    }
  }
}

$border: 1px solid rgba(0, 0, 0, 0.3);
$borderLight: 1px solid rgba(0, 0, 0, 0.2);
$borderVeryLight: 1px solid rgba(0, 0, 0, 0.1);
$footerHeight: calc(5rem + 1px);
$sidePanelWidth: 20rem;
$lightTextOpacity: 0.7;
$smallFontSize: 0.8rem;
$largeFontSize: 1.5rem;
$keySize: 15rem;
$placeholderFillColor: rgba(0, 0, 0, 0.1);

@media print {
  @page {
    size: ledger landscape;
  }

  body{
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ellipsis-animation::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}

.cfg-drawings-index-view {
  * {
    box-sizing: content-box;
  }

  min-width: 85rem;
  font-size: $smallFontSize;
  font-family: arial;

  &,
  .drawings-content {
    height: 100%;
  }

  .drawings-controls {
    position: fixed;
    top: 2rem;
    left: 2rem;
    padding: 0.5rem;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  .drawings-button-item {
    position: relative;
    cursor: pointer !important;
    z-index: 2;
  }

  .drawings-content {
    .drawings-page {
      border: $border;
      page-break-after: always;
      height: calc(100% - 2px);

      .drawings-page-content {
        height: calc(100% - #{$footerHeight});

        .drawings-graphic {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }

        .drawings-side-panel-container {
          height: 100%;
          display: flex;

          .drawings-page-main-content {
            width: calc(100% - 1px - #{$sidePanelWidth} - 4rem);
            height: calc(100% - 4rem);
            padding: 2rem;
            float: left;

            .standard-page-elements {
              position: relative;
              // overflow: hidden;

              .show-loader {
                background-repeat: no-repeat;
                background-size: 50px;
                background-position: center;

                &.loader-light {
                  background-image: url('https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/loader-light.gif');
                }

                &.loader-dark {
                  background-image: url('https://s3-us-west-2.amazonaws.com/henrybuilt-cdn/images/loader-dark.gif');
                }
              }

              .graphic-image {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              .empty.graphic-image {
                color: red;
                background-color: transparent;
                box-shadow: inset 0 0 0 1px red;
              }

              .graphic-label {
                position: absolute;
                left: 0;
                right: 0;
                text-transform: uppercase;
                text-align: center;
                margin: 0 auto;
                min-width: auto;
                font-weight: bold;
                color: grey;
              }

              .empty .graphic-label {
                color: red;
              }

              div {
                &.draggable {
                  cursor: move;
                }

                &.dragging {
                  box-shadow: inset 0 0 0 1px red;
                }
              }
            }
          }

          .drawings-side-panel {
            float: right;
            border-left: $borderLight;
            width: $sidePanelWidth;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .drawings-keys {
              margin-bottom: 1rem;
              float: none;
              border-bottom: $borderVeryLight;

              .drawings-key {
                display: block;
                margin: auto;
                width: $keySize;
                height: $keySize;
              }
            }

            .drawings-side-panel-content {
              flex-grow: 1;
              display: flex;
              flex-direction: column;


              .side-panel-section {
                &.side-panel-details {
                  flex-grow: 1;
                }

                .side-panel-section-header,
                .side-panel-section-content {
                  padding: 1rem;
                }

                .side-panel-section-header {
                  border-bottom: $borderVeryLight;
                  font-size: 1rem;
                }

                .side-panel-section-content {
                  .binding-dimensions-key-image {
                    // object-fit: contain;
                    // background-size: contain;
                    // background-repeat: no-repeat;
                    // background-position: 50% 50%;
                    // width: 17rem;
                    height: $keySize - 6;
                  }

                  .side-panel-list-item {
                    &:not(:last-child) {
                      margin-bottom: 0.4rem;
                    }
                  }

                  .room-title {
                    &:not(:first-child) {
                      margin-top: 1rem;
                    }
                  }

                  .detail-item-with-hatch {
                    padding-bottom: 0.5rem;

                    .detail-item-title,
                    .detail-item-hatch {
                      display: inline-block;
                      vertical-align: middle;
                    }

                    .detail-item-hatch {
                      width: 20px;
                      height: 20px;
                      margin-right: 0.75rem;
                      border: black 1px solid;

                      .canvas-container {
                        border: 1px solid #333;
                      }

                      canvas,
                      .canvas-container {
                        width: 20px !important;
                        height: 20px !important;
                      }
                    }

                    .detail-item-title {
                      width: calc(100% - 22px - 1rem);
                      text-transform: lowercase;
                      font-weight: bold;
                    }
                  }

                  .detail-category {
                    padding-bottom: 1rem;

                    .detail-category-title {
                      opacity: 0.8;
                      // display: inline-block;
                      margin-bottom: 0.3rem;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }

                    .detail-category-items {
                      // padding-left: 0.5rem;

                      .detail-item {
                        text-indent: -1rem;
                        margin-left: 1rem;
                        padding-bottom: 0.25rem;

                        .detail-item-title {
                          text-transform: lowercase;
                          font-weight: bold;
                        }

                        .detail-item-instance-titles {}
                      }
                    }
                  }
                }
              }
            }

            .signature-container {
              width: calc(100% - 2rem);
              align-self: flex-end;
              padding: 1rem;
              height: 8rem;
              display: flex;
              align-items: flex-end;

              .signature-content {
                display: flex;
                border-top: $borderLight;
                opacity: 0.7;
                padding: 0.5rem;
                width: 100%;

                .date-label {
                  flex-grow: 1;
                  text-align: right;
                }
              }
            }
          }
        }
      }

      &.type-cover {
        .drawings-page-footer {
          display: none;
        }

        .approval-container {
          width: 38%;
          position: absolute;
          margin: 20px 0px 0px 10px;
          font-family: 'Libre Franklin', arial;

          .approval-container-content {
            padding-top: 1.5rem;
            padding-left: 2rem;
          }
        }

        .final-approval {
          margin-top: 20px 0px 0px 20px;
          padding-top: 100px 0px 0px 10px;
          font-size: 0.8rem;
          color: grey;
          white-space: pre-line;
        }

        .fir-heading {
          color: red;
          border-bottom: 2px solid grey;
          text-transform: uppercase;
        }

        .sec-heading {
          border-bottom: 2px solid grey;
          text-transform: uppercase;
          width: 70px;
          font-weight: bold;
          color: grey;
        }

        .drawings-page-content {
          position: relative;

          .cover-container {
            display: flex;
            flex-direction: column;
            width: 60%;
            height: 45%;
            position: relative;
            top: 50%;
            left: 40%;

            .company-name,
            .project-name,
            .delivery-address,
            .issue-date,
            .signature-container {
              padding-left: 1rem;
            }

            .company-name {
              border-bottom: $borderLight;
              font-size: 2.8rem;
              width: 90%;

              span {
                opacity: 0.7;
              }
            }

            .project-name,
            .delivery-address,
            .issue-date {
              font-size: 1.5rem;
              margin-top: 1.5rem;
            }

            .project-name {
              opacity: 0.9;
            }

            .delivery-address {
              opacity: 0.75;
            }

            .issue-date {
              opacity: 0.6;
              flex-grow: 1;
            }

            .signature-container {
              display: flex;
              width: 30rem;
              border-top: $borderLight;
              padding-top: 0.5rem;
              padding-right: 0.5rem;
              align-self: flex-end;
              margin-right: 10%;
              opacity: 0.7;

              .date-label {
                flex-grow: 1;
                text-align: right;
              }
            }
          }
        }
      }

      &.type-critical-schedules {
        .critical-schedules-container {
          height: 100%;
          display: flex;

          .critical-schedules-outlines {
            height: 100%;
          }

          .site-access-content-container {
            display: flex;
            flex-direction: column;

            .site-access-dimensions {
              padding: 1rem;
            }

            .site-access-dimensions {
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
              white-space: nowrap;
              flex-grow: 1;

              .site-access-volume {
                width: 33%;

                .volume-title {
                  text-transform: lowercase;
                  text-align: center;
                  font-size: 1rem;

                  .in-packaging {
                    opacity: 0.6;
                  }
                }

                .volume-graphic {
                  height: 30rem;
                }
              }
            }

            .site-access-disclaimer {
              display: flex;
              flex-direction: row;
              border: $borderLight;
              align-items: center;

              .disclaimer-terms-red,
              .disclaimer-terms-black {
                padding: 1rem;
              }

              .disclaimer-terms-red {
                color: red;
                font-weight: bold;
                border-right: $borderLight;

                .red-text-attention {
                  font-size: $largeFontSize;
                  display: block;
                  letter-spacing: 0.25rem;
                  padding-bottom: 0.5rem;
                }
              }

              .disclaimer-terms-black {
                ul {
                  margin: 0;
                  padding-left: 1rem;
                }
              }
            }
          }

          .drawings-side-panel {
            display: flex;

            .drawings-side-panel-content {

              .appliances,
              .glossary {
                .table-cell {
                  padding: 2px 0;

                  &:not(:first-child) {
                    padding-left: 1rem;
                  }
                }
              }

              .glossary {
                .glossary-title {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      &.type-floors {
        .floors-container {
          padding: 2rem;
          height: calc(100% - 4rem);
          display: flex;
          align-items: center;
          justify-content: center;

          .drawings-floor {
            box-sizing: border-box;
            display: inline-block;

            .drawings-floor-graphic {
              width: 100%;
              height: calc(100% - 1.2rem);
            }

            .floor-title {
              height: 1.2rem;
              text-transform: lowercase;
              text-align: center;
            }
          }
        }
      }

      &.type-room,
      &.type-room-binding-dimensions {
        .drawings-page-main-content {
          position: relative;

          .drawings-room-left-panel,
          .room-elevations {
            float: left;
          }

          .drawings-room-left-panel {
            width: 100%; //calc(67% - 2rem);
            height: 100%;
            margin-right: 2rem;

            .drawings-room-graphic {
              width: 100%;
              height: 100%; // calc(100% - #{$keySize} - 2rem);
            }
          }

          .drawings-room-annotations-view-container {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 80%;
            height: 20%;

            .drawings-annotation {
              display: inline;
              margin-left: 2rem;
              min-width: 25rem;
              max-width: 30rem;
            }

            .drawings-room-annotation-title {
              font-weight: bold;
            }
          }

          .room-elevations {
            width: 33%;
            height: 100%;
            display: none;

            .room-elevation {
              height: calc(25% + 0.2rem);

              .room-elevation-graphic {
                height: calc(100% - 2rem - 1.5rem);
                width: 100%;
                text-align: center;
              }

              .room-elevation-title {
                padding-top: 0.5rem;
                text-align: center;
                width: 100%;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      &.type-elevation,
      &.type-elevation-binding-dimensions {
        .elevation-container {
          position: relative;

          .drawings-elevation-graphic {
            width: 100%;
            height: 100%; //calc(100% - #{$keySize} - 2rem);
          }

          .drawings-elevation-annotations-view-container {
            position: absolute;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 80%;
            height: 20%;

            .drawings-elevation-annotation {
              display: inline;
              margin-left: 2rem;
              min-width: 25rem;
              max-width: 30rem;
            }

            .drawings-elevation-annotation-title {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  @media print {
    .drawings-controls {
      display: none;
    }

    .hide-print {
      display: none;
    }

    .drawings-page-settings {
      display: none;
    }

    .drawings-page {
      height: calc(11in - 2px);

      .standard-page-elements {
        height: 100%;
        min-width: 13in;
      }
    }

    .drawings-page-main-content {
      overflow: hidden !important;
    }
  }

  @media not print {
    &:not(.old-mode) {
      background-color: #eee;
      overflow: auto;

      .drawings-content {
        overflow: initial;
        max-width: 17in;
        margin: auto;
        margin-top: 1rem;

        .drawings-page {
          background-color: white;
          margin-right: 1rem;
          margin-left: 1rem;
          margin-bottom: 1rem;
          height: calc(11in - 2px);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

          .drawings-page-main-content {
            overflow-x: auto;
            scrollbar-gutter: stable;
            overflow-y: hidden;

            .standard-page-elements {
              // overflow-y: hidden;
              height: calc(991px - 4rem);
              max-height: calc(991px - 4rem);
              min-width: 13in;
              max-width: 13in;
            }
          }

          &:not(:last-child) {
            border-bottom: none !important;
            height: 11in;
          }
        }
      }
    }

    // .drawings-footer, .drawings-page-footer {
    //   .page-title {
    //     min-width: calc(#{$sidePanelWidth} - 3rem) !important;
    //   }
    // }

    .drawings-content {
      overflow: auto;
      height: 100%;

      .drawings-page {
        height: 100%;
        border: none;

        &:not(:last-child) {
          border-bottom: $border;
          height: 100%;
        }

        &:first-child {
          .drawings-page-content {
            height: 100%;
          }
        }

        &:not(:first-child) {
          .drawings-page-content {
            height: calc(100% - #{$footerHeight});
          }
        }
      }
    }
  }

  &.production-mode {

    .site-access-disclaimer,
    .signature-container {
      display: none !important;
    }
  }
}

body.company-key-hb {
  .cfg-drawings-index-view {
    font-family: 'Libre Franklin', arial !important;
  }
}

.drawings-options-menu {
  position: absolute;
  top: 4rem;
  left: calc(50% - 5rem);
  width: 10rem;
  padding: 1rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  background-color: #eee;
  border-radius: 3px;
  z-index: 2;

  .drawings-footer-item {
    margin-bottom: 0.5rem;
  }

  [class*='-toggle-option'],
  .apply {
    margin-bottom: 1rem;
    text-align: left;
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }

  .apply {
    background-color: #333;
    color: #fff;
    padding: 0.6rem 1rem;
    border: 0;
    cursor: pointer;
    outline: none;
    display: inline-block;
  }
}
