.box-3d-container {
  height: 200px;
  display: flex;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .box-3d {
    margin: auto;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px) rotateX(-45deg) rotateY(45deg);

    .box-face {
      position: absolute;
      border: 1px solid #666;

      .box-dimension-container {
        position: relative;
        width: 100%;
        height: 100%;

        .box-dimension {
          position: absolute;
          border-top: 1px solid #999999;
          width: 100%;

          &.box-dimension-height {
            transform: rotateZ(-90deg);
            top: calc(100%);
            transform-origin: 0% 0%;
            left: calc(100% + 0.4rem);

            .box-dimension-text {
              transform: rotateZ(90deg) rotateX(-45deg) rotateY(-45deg);
              font-size: 1.5rem;
              padding-left: 50%;
              text-align: left;
              position: relative;
              left: -0.9rem;
            }
          }
          &:not(.box-dimension-height) {
            top: calc(100% + 0.5rem);
            left: 0;
          }

          .box-dimension-text {
            text-align: center;
            position: relative;
            top: 0.2rem;
            font-size: 1rem;
          }
        }
      }
    }

    .box-face-right, .box-face-bottom, .box-face-back {
      display: none
    }
    .box-face-top {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .box-face-left  {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .box-face-front  {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .box-face-front {
      transform: rotateY(0deg) translateZ(100px);
    }
    .box-face-right {
      transform: rotateY(90deg) translateZ(100px);
    }
    .box-face-back {
      transform: rotateY(180deg) translateZ(100px);
    }
    .box-face-left {
      transform: rotateY(-90deg) translateZ(100px);
    }
    .box-face-top {
      transform: rotateX(90deg) translateZ(100px);
    }
    .box-face-bottom {
      transform: rotateX(-90deg) translateZ(100px);
    }
  }
}
