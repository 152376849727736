.media-grid {
    .media-grid-cell {
      height: 15rem;
      min-width: 22.6rem;
      background-color: rgba(0, 0, 0, 0.03);
      margin-right: 4px;
      display: inline-block;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.vimage, &.image {
        img {
          height: 100%;
          margin-left: auto;
            margin-right: auto;
            display: block;
          border-radius: 2px;
        }
      }
      &.video {
        width: 14rem;
        position: relative;
        border-radius: 2px;
      }
    }
  }
